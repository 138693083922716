.App {
  text-align: center;
}

p, h1,h2,h3, a, button, input{
  font-family: "Poppins", Sans-serif;
  color:white;
}
p{
  line-height: 2.2rem;
}

.App-logo {
  height: 10rem;
  pointer-events: none;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.bold{
  font-weight: 600;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
   animation: App-logo-spin infinite 20s linear; 
  }
} */

.App-main {
  background-color: #051227;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input{
  width:100%;
  height:30px;
  padding:0.2rem 0.6rem;
  border-radius: 5px;
  font-weight: 600;
  color:black
}

h1{
  font-size: 1.5rem;
}
p, a{
  margin-bottom:  10px;
  line-height: 30px;
  color:white;
  font-size:1.2rem;
}
.small-text{
  font-size: 0.9rem;

}

.button{
  background-color: #08234C;
  border-radius:10px;
  height:40px;
  box-shadow: none;
  color:white;
  cursor: pointer;
  border: 1px solid #cbcbcb;
}
.button:hover{
  background-color: #06152d;

}
.Toastify__toast-body{
  font-size: 17px;
}
.progress-bar-done {
	background: linear-gradient(to left, #F2709C, #FF9472);
	box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C;
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}

 